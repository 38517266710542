import React, {Component} from "react";
import {AuthConsumer} from "../utils/AuthContext";

class HomePage extends Component {
    render() {
        return (
            <AuthConsumer>
            {
                ({authName}) => (<div>
                    <div className="jumbotron">
                        <h1 className="display-4">Welcome back, {authName}</h1>
                        <p className="lead"></p>
                    </div>
                </div>)
            }
            </AuthConsumer>
        );
    }
}

export default HomePage;
