import React, {Component} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AllLicencesPage from "./pages/AllLicencesPage";
import AllStatusesPage from "./pages/AllStatusesPage";
import NewLicencePage from "./pages/NewLicencePage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import StatusPage from "./pages/StatusPage";
import LicencePage from "./pages/LicencePage";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

import {AuthConsumer} from "./utils/AuthContext";

class App extends Component {
    render() {
        return (<AuthConsumer>
            {
                ({isAuth, hasLoaded}) => hasLoaded
                    ? (
                        isAuth
                        ? (<div>
                            <Header/>

                            <div className="container-fluid">
                                <div className="row">
                                    <Sidebar/>

                                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                                        <Switch>
                                            <Route exact path="/" component={HomePage}/>
                                            <Route exact path="/statuses" component={AllStatusesPage}/>
                                            <Route path="/statuses/:serial" component={StatusPage}/>
                                            <Route exact path="/licences" component={AllLicencesPage}/>
                                            <Route exact path="/newlicence" component={NewLicencePage}/>
                                            <Route path="/licences/:licenceId" component={LicencePage}/>
                                            <Redirect to="/"/>
                                        </Switch>
                                    </main>
                                </div>
                            </div>
                        </div>)
                        : (<LoginPage/>))
                    : (<div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>)
            }
        </AuthConsumer>);
    }
}

export default App;
