import React, {Component} from 'react';

class NewLicencePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputExpiryDate: null,
            inputSerialNumber: null,
            inputLicenceType: "standard"
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;

        this.setState({[target.name]: target.value});
    }

    handleSubmit(event) {
        event.preventDefault();

        fetch("/api/licences/new", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serialNumber: this.state.inputSerialNumber,
                expiryDate: this.state.inputExpiryDate,
                type: this.state.inputLicenceType
            })
        })
        .then(res => res.json())
        .then((result) => {
            if (result.success) {
                this.props.history.push('/licences/' + result.licenceId);
            } else {
                alert("Error creating new licence");
            }
        }, (error) => {
            alert("Error creating new licence");
        });
    }

    render() {
        return (<div>
            <h1>
                Create New Licence
            </h1>

            <hr/>

            <form className="needs-validation" noValidate={false} onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="inputSerialNumber">MGS Serial Number</label>
                    <input
                        name="inputSerialNumber" type="text" className="form-control" id="inputSerialNumber"
                        pattern="520A[0-9]{4}" value={this.state.inputSerialNumber || ''} onChange={this.handleInputChange} required="required"/>
                    <small id="emailHelp" className="form-text text-muted">This is of the form 520Axxxx</small>
                </div>
                <div className="form-group">
                    <label htmlFor="inputExpiryDate">Expiry Date</label>
                    <input
                        name="inputExpiryDate" type="date" className="form-control" id="inputExpiryDate"
                        value={this.state.inputExpiryDate || ''}
                        onChange={this.handleInputChange}
                        required="required"
                        min={(new Date()).toISOString().substring(0,10)}/>
                </div>

                <fieldset name="inputLicenceType" className="form-group">
                    <div className="custom-control custom-radio">
                        <input
                            type="radio" id="inputLicenceTypeStandard"
                            name="inputLicenceType" className="custom-control-input" value="standard"
                            checked={this.state.inputLicenceType === "standard"} onChange={this.handleInputChange}/>
                        <label className="custom-control-label" htmlFor="inputLicenceTypeStandard">Standard Licence</label>
                    </div>
                    <div className="custom-control custom-radio">
                        <input
                            type="radio" id="inputLicenceTypeGrace" name="inputLicenceType"
                            className="custom-control-input" value="grace"
                            checked={this.state.inputLicenceType === "grace"} onChange={this.handleInputChange}/>
                        <label className="custom-control-label" htmlFor="inputLicenceTypeGrace">Grace Licence</label>
                    </div>
                </fieldset>

                <input type="submit" value="Generate" className="btn btn-primary"/>
            </form>
        </div>);
    }
}

export default NewLicencePage;
