import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import { Home, TrendingUp, Key }  from "react-feather";

class Sidebar extends Component {
    render() {
        return (<nav className="col-md-2 d-none d-md-block bg-light sidebar">
            <div className="sidebar-sticky">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink exact={true} className="nav-link" activeClassName="nav-link active" to="/">
                            <Home className="feather"/>
                            Home
                        </NavLink>
                    </li>
                </ul>
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Overview</span>
                </h6>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink className="nav-link" activeClassName="nav-link active" to="/statuses">
                            <span data-feather="layers"></span>
                            <TrendingUp className="feather"/>
                            View Statuses
                        </NavLink>
                    </li>
                </ul>
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Licensing</span>
                </h6>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <NavLink className="nav-link" activeClassName="nav-link active" to="/licences">
                            <Key className="feather"/>
                            View Licences
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" activeClassName="nav-link active" to="/newlicence">
                            <Key className="feather"/>
                            Create New Licence
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>);
    }
}

export default Sidebar;
