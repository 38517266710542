import React, {Component} from "react";

const AuthContext = React.createContext();

class AuthProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuth: false,
            authName: null,
            hasLoaded: false
        };

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    login() {
        setTimeout(() => this.setState({isAuth: true}), 1000);
    }

    logout() {
        this.setState({isAuth: false});
    }

    componentDidMount() {
        fetch("/auth/user").then(res => res.json()).then(result => {
            this.setState({
                isAuth: result.authenticated,
                authName: result.authenticated
                    ? result.user.first_name + " " + result.user.last_name
                    : "",
                hasLoaded: true
            });
        }, error => {
            console.log("oh no");
        });
    }

    render() {
        return (
            <AuthContext.Provider value={{
                isAuth: this.state.isAuth,
                authName: this.state.authName,
                hasLoaded: this.state.hasLoaded,
                login: this.login,
                logout: this.logout
            }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

const AuthConsumer = AuthContext.Consumer;

export {
    AuthProvider,
    AuthConsumer
};
