import React, {Component} from "react";
import {AuthConsumer} from "../utils/AuthContext";
import { User, LogOut }  from "react-feather";

class Header extends Component {
    render() {
        return (<nav className="navbar navbar-dark navbar-expand sticky-top bg-dark flex-md-nowrap p-0">
            <div className="navbar-brand-sink col-sm-3 col-md-2 mr-0">
                <a className="navbar-brand" href="/">
                    <img width="25" height="25" className="rounded d-inline-block align-top" src={require("../amt-logo-white.png")} alt="AMT"/>
                    MGS Monitor
                </a>
            </div>

            <AuthConsumer>
                {
                    ({isAuth, authName, login, logout}) => (<ul className="navbar-nav px-3 ml-auto">
                        <li className="nav-item dropdown mr-0">
                            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <User className="feather"/> {authName}
                            </a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                <a onClick={logout} className="dropdown-item" href="/auth/logout">
                                    <LogOut className="feather"/> Logout
                                </a>
                            </div>
                        </li>
                    </ul>)
                }
            </AuthConsumer>
        </nav>);
    }
}

export default Header;
