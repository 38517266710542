import React, {Component} from "react";
import { ArrowLeft, ArrowRight, RefreshCcw, Download }  from "react-feather";
import {Link} from "react-router-dom";

class LicencePage extends Component {
    constructor(props) {
        super(props);

        const { licenceId } = this.props.match.params;

        this.state = {
            isLoaded: false,
            licenceId: licenceId,
            licenceData: {
                id: "",
                serial: "",
                type: "",
                expiryDate: "",
                generatedDate: "",
                data: [],
                info: "",
                hasStatus: false,
                bufferString: ""
            }
        };

        this.loadData = this.loadData.bind(this);
        this.downloadLicenceFile = this.downloadLicenceFile.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        fetch("/api/licences/" + this.state.licenceId)
        .then(res => res.json())
        .then((result) => {
            if (result.success) {
                this.setState({ isLoaded: true, licenceData: result.data });
            } else {
                this.setState({ isLoaded: false });
                this.props.history.push('/licences/');
            }
        },
        (error) => {
            this.setState({ isLoaded: false });
            this.props.history.push('/licences/');
        });
    }

    downloadLicenceFile() {
        const blob = new Blob([new Uint8Array(this.state.licenceData.data.data)], { type: 'application/octet-stream' });
        const fileName = `lic${this.state.licenceId}-mgs${this.state.licenceData.serial}`
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    render() {
        return (<div>
            <h1 style={{display: "inline"}}>Licence #{this.state.licenceId}</h1>

            <div className="btn-toolbar float-right d-inline-block align-top mt-2" role="toolbar">
                <div className="btn-group mr-2" role="group" aria-label="Back">
                    <button onClick={this.props.history.goBack} to="/statuses" type="button" className="btn btn-outline-danger btn-sm">
                        <ArrowLeft className="feather"/> Back
                    </button>
                </div>
                <div className="btn-group" role="group" aria-label="Refresh">
                    <button type="button" className="btn btn-outline-secondary btn-sm"><RefreshCcw className="feather" onClick={this.loadData}/> Refresh</button>
                </div>
            </div>

            <hr/>

            <div className="card border-light mb-3">
                <div className="card-header">
                    <strong>Information</strong>
                </div>
                <div className="card-body">

                    <table className="table table-borderless">

                        <tbody>
                            <tr>
                                <th className="w-25" scope="row">Licence ID</th>
                                <td className="w-75">{this.state.licenceId}</td>
                            </tr>
                            <tr>
                                <th className="w-25" scope="row">MGS Serial Number</th>
                                <td className="w-70">{this.state.licenceData.serial}</td>
                                <td className="w-5">
                                {
                                    this.state.licenceData.hasStatus ?
                                    <Link to={`/statuses/${this.state.licenceData.serial}`}
                                        className="btn btn-outline-primary btn-block btn-sm float-right">
                                        <ArrowRight/>
                                    </Link>
                                    :
                                    <button disabled className="btn btn-outline-secondary btn-block btn-sm float-right" > <ArrowRight/> </button>
                                }
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25" scope="row">Licence Type</th>
                                <td className="w-75">{this.state.licenceData.type}</td>
                            </tr>
                            <tr>
                                <th scope="row">Generated Date</th>
                                <td>{this.state.licenceData.generatedDate}</td>
                            </tr>
                            <tr>
                                <th className="w-25" scope="row">Expiry Date</th>
                                <td className="w-75">{this.state.licenceData.expiryDate}</td>
                            </tr>
                            <tr>
                                <th className="w-25" scope="row">Licence Info</th>
                                <td className="w-75">{this.state.licenceData.info}</td>
                            </tr>
                            <tr>
                                <th className="w-25" scope="row">Base64 String</th>
                                <td className="w-75">
                                    <p className="text-break">
                                        {this.state.licenceData.bufferString}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25" scope="row">Licence File</th>
                                <td className="w-75">
                                    <button className="btn btn-primary btn-sm" onClick={this.downloadLicenceFile}>
                                        <Download /> Download
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>);
    }
}

export default LicencePage;
