import React, {useState, useEffect} from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    FormGroup
} from "reactstrap";
import { Edit, Trash2 }  from "react-feather";

const NoteEditModal = props => {
    const {saveCallback} = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [keyValue, setKeyValue] = useState("");
    const [valueValue, setValueValue] = useState("");

    useEffect(() => {
        setKeyValue(props.oldKey);
        setValueValue(props.oldValue);
    }, [props.oldKey, props.oldValue]);

    const save = () => {
        saveCallback(props.oldKey, keyValue, valueValue);
        toggle();
    };

    const discard = () => {
        setKeyValue(props.oldKey);
        setValueValue(props.oldValue);
        toggle();
    };

    return (<div>
        <div className="btn-group mr-2" role="group" aria-label="First group">
            <button type="button" className="btn btn-sm btn-outline-primary" onClick={toggle}>
                <Edit className="feather"/>
            </button>
        </div>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Edit Note</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Key</Label>
                    <Input type="text" className={keyValue === ""
                            ? "is-invalid"
                            : ""} value={keyValue} onChange={e => setKeyValue(e.target.value)} placeholder="Key"/>
                </FormGroup>
                <FormGroup>
                    <Label>Value</Label>
                    <Input type="textarea" className={valueValue === ""
                            ? "is-invalid"
                            : ""} value={valueValue} onChange={e => setValueValue(e.target.value)} placeholder="Value" rows={5}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={discard}>
                    Cancel
                </Button>
                <Button color="primary" onClick={save} disabled={keyValue === ""}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    </div>);
};

const EditNameModal = props => {
    const {saveCallback} = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [name, setName] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        setName(props.oldName);
    }, [props.oldName]);

    const save = () => {
        setButtonDisabled(true);
        saveCallback(name);
        toggle();
        setButtonDisabled(false);
    };

    const discard = () => {
        setName(props.oldName);
        toggle();
    };

    return (<div>
        <button type="button" className="btn btn-outline-primary btn-sm" onClick={toggle}>
            <Edit className="feather"/>
        </button>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Edit Name</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>New Name</Label>
                    <Input type="text" required="required" className={name === ""
                            ? "is-invalid"
                            : ""} value={name} onChange={e => setName(e.target.value)} placeholder="Name"/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={discard}>
                    Cancel
                </Button>
                <Button color="primary" onClick={save} disabled={buttonDisabled || name === ""}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    </div>);
};


const ConfirmationModal = props => {
    const {deleteCallback} = props;

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const doDelete = () => {
        setModal(false);
        deleteCallback(props.noteId);
    };

    return (<div>
        <div className="btn-group" role="group" aria-label="Second group">
            <button type="button" className="btn btn-sm btn-outline-danger" onClick={toggle}>
                <Trash2 className="feather"/>
            </button>
        </div>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Confirm Deletion</ModalHeader>
            <ModalBody>
                Are you sure you want to delete this note? This action cannot be reversed.
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="danger" onClick={doDelete}>
                    Delete
                </Button>
            </ModalFooter>
        </Modal>
    </div>);
};


export {
    NoteEditModal,
    EditNameModal,
    ConfirmationModal
};
