import React, {Component} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ArrowRight, ArrowLeft, RefreshCcw, Plus }  from "react-feather";
import {Link} from "react-router-dom";
import {NoteEditModal, EditNameModal, ConfirmationModal} from "../components/Modal";
import {Badge} from "reactstrap";

class StatusPage extends Component {
    constructor(props) {
        super(props);

        const { serial } = this.props.match.params;

        this.state = {
            isLoaded: false,
            serialNumber: serial,
            statusData: {
                id: "",
                name: "",
                version: "",
                roomName: "",
                roomInfo: "",
                lastConnectedTime: "",
                notes: [],
                licences: []
            },
            newKeyValue: "",
            newValueValue: ""
        };

        this.state.columns = [
            {
                dataField: "id",
                text: "Licence ID"
            }, {
                dataField: "type",
                text: "Licence Type"
            }, {
                dataField: "expiryDate",
                text: "Expiry Date"
            }, {
                dataField: "mostRecent",
                text: "",
                formatter: (cell, row) => {
                    const result = [];

                    if (row.expired) {
                        result.push(
                            <Badge key={row + "-" + cell + "-be"} className="mr-1" color="danger">
                                Expired
                            </Badge>
                        );
                    }

                    if (row.mostRecent) {
                        result.push(
                            <Badge key={row + "-" + cell + "-mr"} className="mr-1" color="primary">
                                Most Recent
                            </Badge>
                        );
                    }

                    return <div key={row + "-" + cell}>{result}</div>;
                }
            }, {
                dataField: "view",
                text: "",
                isDummyField: true,
                formatter: (cell, row) => {
                    return  <Link
                                to={`/licences/${row.id}`}
                                className="btn btn-outline-primary btn-block btn-sm">
                                <ArrowRight/>
                            </Link>;
                }
            }
        ];

        this.loadData = this.loadData.bind(this);
        this.updateNote = this.updateNote.bind(this);
        this.newNote = this.newNote.bind(this);
        this.deleteNote = this.deleteNote.bind(this);
        this.updateName = this.updateName.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    loadData() {
        this.setState({isLoaded: false});
        fetch("/api/statuses/" + this.state.serialNumber)
        .then(res => res.json())
        .then(result => {
            if (result.success) {
                this.setState({isLoaded: true, statusData: result.data});
            } else {
                this.setState({ isLoaded: false });
                this.props.history.push('/statuses/');
            }
        }, error => {
            this.setState({isLoaded: true, error});
            this.props.history.push('/statuses/');
        });
    }

    updateNote(oldKey, newKey, newValue) {
        fetch("/api/statuses/" + this.state.serialNumber + "/notes", {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({oldKey: oldKey, newKey: newKey, newValue: newValue})
        }).then(res => res.json()).then(result => {
            this.loadData();
        }, error => {
            console.log("update note error");
        });
    }

    newNote() {
        const {newKeyValue, newValueValue} = this.state;
        this.setState({newKeyValue: "", newValueValue: ""});

        fetch("/api/statuses/" + this.state.serialNumber + "/notes", {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({key: newKeyValue, value: newValueValue})
        }).then(res => res.json()).then(result => {
            this.loadData();
        }, error => {
            console.log("Error creating new note");
            this.setState({newKeyValue: newKeyValue, newValueValue: newValueValue});
        });
    }

    deleteNote(key) {
        fetch("/api/statuses/" + this.state.serialNumber + "/notes", {
            method: "delete",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({noteKey: key})
        }).then(res => res.json()).then(result => {
            this.loadData();
        }, error => {
            console.log("delete note error");
        });
    }

    updateName(newName) {
        fetch("/api/statuses/" + this.state.serialNumber, {
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({newName: newName})
        }).then(res => res.json()).then(result => {
            this.loadData();
        }, error => {
            console.log("update note error");
        });
    }

    render() {
        return (<div>
            <h1 style={{
                    display: "inline"
                }}>
                {this.state.statusData.name}
            </h1>

            <div className="btn-toolbar float-right d-inline-block align-top mt-2" role="toolbar">
                <div className="btn-group mr-2" role="group" aria-label="Back">
                    <button onClick={this.props.history.goBack} to="/statuses" type="button" className="btn btn-outline-danger btn-sm">
                        <ArrowLeft className="feather"/>
                        Back
                    </button>
                </div>
                <div className="btn-group" role="group" aria-label="Refresh">
                    <button type="button" className="btn btn-outline-secondary btn-sm">
                        <RefreshCcw className="feather" onClick={this.loadData} disabled={!this.state.isLoaded}/>  Refresh
                    </button>
                </div>
            </div>

            <hr/>

            <div className="card border-light mb-3">
                <div className="card-header">
                    <strong>Information</strong>
                </div>
                <div className="card-body">
                    <table className="table table-sm table-borderless mb-0 mt-0">
                        <tbody>
                            <tr>
                                <th className="w-25" scope="row">
                                    Name Identifier
                                </th>
                                <td className="w-70">{this.state.statusData.name}</td>
                                <td className="w-5">
                                    <EditNameModal
                                        oldName={this.state.statusData.name}
                                        saveCallback={this.updateName}/>
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25" scope="row">
                                    Serial Number
                                </th>
                                <td className="w-75">{this.state.serialNumber}</td>
                            </tr>

                            <tr>
                                <th className="w-25" scope="row">
                                    Firmware Version
                                </th>
                                <td className="w-75">{this.state.statusData.version}</td>
                            </tr>
                            <tr>
                                <th scope="row">Last Connected Time</th>
                                <td>{this.state.statusData.lastConnectedTime}</td>
                            </tr>
                            <tr>
                                <th scope="row">Room Name</th>
                                <td>{this.state.statusData.roomName}</td>
                            </tr>
                            <tr>
                                <th scope="row">Room Information</th>
                                <td>{this.state.statusData.roomInfo}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="card border-light mb-3">
                <div className="card-header">
                    <strong>Licences</strong>
                </div>
                <div className="card-body">
                    <BootstrapTable
                        keyField="id"
                        data={this.state.statusData.licences}
                        columns={this.state.columns}
                        bordered={false}
                        hover={true}
                        condensed={true}
                        classes="table-sm table-borderless mb-0"
                        noDataIndication="No items to display"/>
                </div>
            </div>

            <div className="card border-light mb-3">
                <div className="card-header">
                    <strong>Notes</strong>
                </div>
                <div className="card-body">
                    <form>
                        <table className="table table-borderless mb-0">
                            <tbody>
                                {
                                    this.state.statusData.notes.map(note => (
                                        <tr key={note.key}>
                                            <th className="w-25" scope="row">
                                                {note.key}
                                            </th>
                                            <td className="w-65 tdbreak">
                                                {
                                                    note.value.split("\n").map((item, idx) => {
                                                        return (
                                                            <span key={idx}>
                                                                {item}
                                                                <br/>
                                                            </span>
                                                        );
                                                    })
                                                }
                                            </td>
                                            <td className="w-10">
                                                <div className="btn-toolbar float-right" role="toolbar" aria-label="Toolbar with button groups">
                                                    <NoteEditModal
                                                        className=""
                                                        oldKey={note.key}
                                                        oldValue={note.value}
                                                        saveCallback={this.updateNote}/>

                                                    <ConfirmationModal
                                                        noteId={note.key}
                                                        deleteCallback={this.deleteNote}/>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <th className="w-25" scope="row">
                                        <input
                                            className="form-control form-control-sm mt-0 mb-0"
                                            type="text"
                                            alue={this.state.newKeyValue}
                                            name="newKeyValue"
                                            onChange={this.handleChange}
                                            placeholder="New Key"/>
                                    </th>
                                    <td className="w-65">
                                        <div className="form-group">
                                            <textarea
                                                value={this.state.newValueValue}
                                                className="form-control form-control-sm mt-0 mb-0"
                                                rows="1"
                                                name="newValueValue"
                                                onChange={this.handleChange}
                                                placeholder="New Value"/>
                                        </div>
                                    </td>
                                    <td className="w-10">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-success w-100 float-right"
                                            disabled={this.state.newKeyValue === "" || this.state.newValueValue === ""}
                                            onClick={this.newNote}>

                                            <Plus className="feather"/> Add
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>);
    }
}
export default StatusPage;
