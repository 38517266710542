import React, {Component} from "react";

class LoginPage extends Component {
    render() {
        return (
            <div className="text-center">
                <div className="form-signin">
                    <img className="mb-4" src={require("../amt-logo.png")}
                        alt=""
                        width="72"
                        height="72"/>
                    <h1 className="h3 mb-3 font-weight-normal">AMT MGS Monitor</h1>
                    <a  href="/auth/login-adfs"
                        className="btn btn-primary btn-lg active btn-block"
                        role="button">
                        Sign in with Microsoft
                    </a>
                </div>
            </div>
        );
    }
}

export default LoginPage;
