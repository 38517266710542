import React, {Component} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ArrowRight }  from "react-feather";
import {Link} from "react-router-dom";
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
const { SearchBar, ClearSearchButton } = Search;

class AllLicencesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            columns: [
                {
                    dataField: "id",
                    text: "Licence ID",
                    sort: true
                }, {
                    dataField: "serial",
                    text: "MGS Serial Number",
                    sort: true
                }, {
                    dataField: "type",
                    text: "Licence Type",
                    sort: true
                }, {
                    dataField: "expiryDate",
                    text: "Expiry Date",
                    sort: true
                }, {
                    dataField: "isMostRecent",
                    text: "Most Recent Licence",
                    sort: true,
                }, {
                    dataField: "view",
                    text: "",
                    isDummyField: true,
                    formatter: (cell, row) => {
                        return (
                            <Link to={`/licences/${row.id}`} className="btn btn-outline-primary btn-block btn-sm" href="#">
                                <ArrowRight/>
                            </Link>
                        );
                    }
                }
            ]
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        fetch("/api/licences/all").then(res => res.json()).then((result) => {
            console.log(result.data);
            this.setState({isLoaded: true, data: result.data});
        },
        (error) => {
            this.setState({isLoaded: true, error});
        });
    }

    render() {
        return (
            <div>
                <h1>
                    All Licences
                </h1>

                <hr/>
                <ToolkitProvider
                    keyField="id"
                    data={this.state.data}
                    columns={this.state.columns}
                    search>
                    { props => (
                        <div>
                            <SearchBar className="mb-2" { ...props.searchProps } />
                            <ClearSearchButton className="btn btn-outline-danger ml-1 align-top" { ...props.searchProps } />
                            <BootstrapTable
                                { ...props.baseProps }
                                bordered={false}
                                hover={true}
                                condensed={true}
                                classes="table-sm"
                                pagination={paginationFactory()}
                                noDataIndication="No items to display"
                                />
                        </div>
                    )}
                </ToolkitProvider>
            </div>
        );
    }
}
export default AllLicencesPage;
