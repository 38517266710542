import React, {Component} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ArrowRight } from "react-feather";
import {Link} from "react-router-dom";
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';


const { SearchBar, ClearSearchButton } = Search;

class AllStatusesPage extends Component {

    constructor(props) {
        super(props);
        this.viewButtonFormatter = ((cell, row) => {
            return (
                <Link
                    to={`/statuses/${row.id}`}
                    className="btn btn-outline-primary btn-block btn-sm" href="#">
                    <ArrowRight/>
                </Link>)
        });

        this.state = {
            hasLoaded: false,
            statusData: []
        };
        this.state.columns = [
            {
                dataField: "id",
                text: "Serial Number (ID)",
                sort: true
            }, {
                dataField: "name",
                text: "MGS Name",
                sort: true
            }, {
                dataField: "time",
                text: "Last Connected Time",
                sort: true
            }, {
                dataField: "version",
                text: "Firmware Version",
                sort: true
            }, {
                dataField: "view",
                text: "",
                isDummyField: true,
                formatter: this.viewButtonFormatter
            }
        ];
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        fetch("/api/statuses/all").then(res => res.json()).then((result) => {
            this.setState({isLoaded: true, statusData: result.data});
        },
        (error) => {
            this.setState({isLoaded: true, error});
        });
    }

    render() {
        return (
            <div>
                <h1>
                    All Statuses
                </h1>

                <hr/>

                <ToolkitProvider
                    keyField="id"
                    data={this.state.statusData}
                    columns={this.state.columns}
                    search>
                    { props => (
                        <div>
                            <SearchBar className="mb-2" { ...props.searchProps } />
                            <ClearSearchButton
                                className="btn btn-outline-danger ml-1 align-top"
                                { ...props.searchProps } />
                            <BootstrapTable
                                { ...props.baseProps }
                                bordered={false}
                                hover={true}
                                condensed={true}
                                classes="table-sm"
                                pagination={paginationFactory()}
                                noDataIndication="No items to display" />
                        </div>
                    )}
                </ToolkitProvider>

            </div>
        );
    }
}
export default AllStatusesPage;
